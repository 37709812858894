import { BaseModel, User, Adviser, Investor, Offer } from '@/models';
import RemoteApplicationStatusEnum from '@/enums/remoteApplication/status';
import RemoteApplicationBlockStatusEnum from '@/enums/remoteApplication/blockStatus';
import RemoteApplicationBlockFundsStatusEnum from '@/enums/remoteApplication/blockFundsStatus';
import DynamicFormTypeEnum from '@/enums/dynamicForm/type';
import getValidatorFromString from '@/lib/helpers/getValidatorFromString';
import OfferApi from '@/api/OfferApi';
import AdviserApi from '@/api/AdviserApi';
import InvestorApi from '@/api/InvestorApi';
import UserApi from '@/api/UserApi';
import RemoteApplicationApi from '@/api/RemoteApplicationApi';

export class RemoteApplication extends BaseModel {
    static entity = 'remoteApplications';
    static Api = RemoteApplicationApi;

    static fields() {
        return {
            ...super.fields(),
            id: this.string(null).nullable(),
            data: this.attr(null).nullable(),
            data_template: this.attr(null).nullable(),
            blocks: this.attr([]).nullable(),
            document: this.attr(null).nullable(),
            remote_id: this.string(null).nullable(),
            api_remote_id: this.string(null).nullable(),
            status: this.enum(RemoteApplicationStatusEnum, RemoteApplicationStatusEnum.DRAFT).nullable(),

            funds_cleared: this.number(0),
            most_recent_funds_cleared_at: this.string(null).nullable(),
            blocks_allotted: this.number(0),
            most_recent_blocks_allotted_at: this.string(null).nullable(),

            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),
            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),
            offer_id: this.string(null).nullable(),
            offer: this.belongsTo(Offer, 'offer_id'),
            proposal_id: this.string(null).nullable(),
            proposal: this.belongsTo(Offer, 'proposal_id'),
            submitted_at: this.string(null).nullable(),
            submitted_by_id: this.string(null).nullable(),
            submitted_by: this.belongsTo(User, 'submitted_by_id'),
            created_at: this.string(null).nullable(),
            created_by_id: this.string(null).nullable(),
            created_by: this.belongsTo(User, 'created_by_id'),
            last_updated: this.string(null).nullable(),
            last_updated_by_id: this.string(null).nullable(),
            last_updated_by: this.belongsTo(User, 'last_updated_by_id')
        };
    }

    static mock() {
        return {
            id: faker => faker.string.uuid(),
            data: null,
            data_template: [
                {
                    label: 'Amount',
                    name: 'amount',
                    type: DynamicFormTypeEnum.MONEY,
                    rules: ['required', 'min:1000', 'max:1000000'],
                    schema: null
                },
                {
                    label: 'Another Amount',
                    name: 'another_amount',
                    type: DynamicFormTypeEnum.MONEY,
                    rules: ['required', 'min:10000'],
                    schema: null
                },
                {
                    label: 'Date',
                    name: 'date',
                    type: DynamicFormTypeEnum.DATE,
                    rules: [],
                    schema: null
                }
            ],
            document: null,
            remote_id: faker => faker.string.uuid().slice(0, 8),
            api_remote_id: faker => faker.string.uuid(),
            status: faker => faker.helpers.arrayElement(Object.keys(RemoteApplicationStatusEnum)),

            offer: OfferApi,
            offer_id: (faker, item) => item.offer.id,

            adviser: AdviserApi,
            adviser_id: (faker, item) => item.adviser.id,

            investor: InvestorApi,
            investor_id: (faker, item) => item.investor.id,

            blocks: (faker, item) => {
                if (item.status !== RemoteApplicationStatusEnum.SENT) {
                    return [];
                }

                return item.data_template
                    .filter(field => field.type === DynamicFormTypeEnum.MONEY)
                    .map(field => ({
                        label: field.label,
                        amount: item.data?.[field.name]?.amount || faker.number.float(1000, 100000),
                        investment_amount: item.data?.[field.name]?.amount || faker.number.float(1000, 100000),
                        status: faker.helpers.arrayElement(Object.keys(RemoteApplicationBlockStatusEnum)),
                        funds_status: faker.helpers.arrayElement(Object.keys(RemoteApplicationBlockFundsStatusEnum)),
                        allotment_date: faker.date.past().toISOString(),
                        funds_cleared_at: faker.date.past().toISOString(),
                        number_of_shares: faker.number.int(1000)
                    }));
            },

            funds_cleared: (faker, item) => faker.number.int({ max: item.blocks.length }),
            most_recent_funds_cleared_at: (faker, item) =>
                item.funds_cleared ? faker.date.past().toISOString() : null,
            blocks_allotted: (faker, item) => faker.number.int({ max: item.blocks.length }),
            most_recent_blocks_allotted_at: (faker, item) =>
                item.blocks_allotted ? faker.date.past().toISOString() : null,

            submitted_at: (faker, item) =>
                item.status === RemoteApplicationStatusEnum.SENT ? faker.date.past().toISOString() : null,
            submitted_by: (faker, item) => (item.status === RemoteApplicationStatusEnum.SENT ? UserApi : null),
            submitted_by_id: (faker, item) =>
                item.status === RemoteApplicationStatusEnum.SENT ? item.submitted_by.id : null,
            created_at: (faker, item) => faker.date.between({ from: item.submitted_at, to: new Date() }).toISOString(),
            created_by: UserApi,
            created_by_id: (faker, item) => item.created_by.id,
            last_updated: (faker, item) => item.submitted_at || item.created_at,
            last_updated_by: (faker, item) => {
                if (item.submitted_by_id) {
                    return item.submitted_by;
                }
                if (item.created_by_id) {
                    return item.created_by;
                }
                return UserApi;
            },
            last_updated_by_id: (faker, item) => item.last_updated_by.id
        };
    }

    async $submit() {
        if (this.status !== RemoteApplicationStatusEnum.DRAFT) {
            throw new Error('RemoteApplication must be in draft status to be submitted');
        }

        const { data } = await RemoteApplication.api.submit(this.id);
        return data;
    }

    async $copy() {
        const copy = new RemoteApplication(this.$toPayload());

        copy.id = null;
        copy.status = RemoteApplicationStatusEnum.DRAFT;
        copy.submitted_at = null;
        copy.submitted_by_id = null;
        copy.submitted_by = null;
        copy.created_at = null;
        copy.created_by_id = null;
        copy.created_by = null;
        copy.last_updated = null;

        return await copy.$create();
    }

    get is_valid() {
        if (this.status === RemoteApplicationStatusEnum.ACCEPTED) {
            return true;
        }
        if (this.status === RemoteApplicationStatusEnum.SENT) {
            return true;
        }
        if (!this.data_template || !this.data) {
            return false;
        }
        return this.validateData(this.data_template, this.data);
    }

    validateData(fields = [], data = null) {
        if (!fields || !fields.length) {
            return false;
        }

        if (data === null) {
            return false;
        }

        return fields.every(field => {
            let isValid = this.validateDataField(field, data[field.name]);

            if (isValid && field.type === DynamicFormTypeEnum.ARRAY) {
                if (Array.isArray(data[field.name])) {
                    isValid = data[field.name].every(arrayData => this.validateData(field.schema, arrayData) === true);
                }
            }

            return isValid;
        });
    }

    validateDataField(field, data = null) {
        if (!field.rules || !Array.isArray(field.rules)) {
            return true;
        }

        const validators = field.rules.map(rule => getValidatorFromString(rule));

        const results = validators.map(({ validator, args }) => {
            return validator(data, field.label, ...args);
        });

        return results.every(result => result === true);
    }

    get can_be_viewed() {
        return true;
    }

    get can_be_edited() {
        return this.status === RemoteApplicationStatusEnum.DRAFT;
    }

    get can_be_submitted() {
        return this.status === RemoteApplicationStatusEnum.DRAFT && this.is_valid;
    }

    get can_be_copied() {
        return this.status !== RemoteApplicationStatusEnum.DRAFT;
    }

    get can_be_deleted() {
        return this.status === RemoteApplicationStatusEnum.DRAFT;
    }

    get is_draft() {
        return this.status === RemoteApplicationStatusEnum.DRAFT;
    }

    get is_sent() {
        return this.status === RemoteApplicationStatusEnum.SENT;
    }

    get is_accepted() {
        return this.status === RemoteApplicationStatusEnum.ACCEPTED;
    }

    get is_rejected() {
        return this.status === RemoteApplicationStatusEnum.REJECTED;
    }

    get has_blocks() {
        return this.blocks && this.blocks.length;
    }

    get blocks_active() {
        if (!this.blocks || !Array.isArray(this.blocks)) {
            return [];
        }

        return this.blocks.filter(
            block =>
                block.status !== RemoteApplicationBlockStatusEnum.NONE &&
                block.funds_status !== RemoteApplicationBlockFundsStatusEnum.NONE
        );
    }

    get block_count() {
        return this.blocks_active.length;
    }
}

export default RemoteApplication;
